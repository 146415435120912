*, *:before, *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
}
a[href]:after {
    content: " (" attr(href) ")";
}
#header .navbar{
    display:none;
}
.main-container{
    padding-top:0;
    padding-bottom:0;
}
h1, h2, h3, h4, h5, h6{
    text-transform:none;
}